// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./sell_canvas_prints"
import "trix"
import "@rails/actiontext"
import "@rails/activestorage"
import * as Sentry from "@sentry/browser"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start();

const railsEnv = document.querySelector('meta[name="rails-env"]').content;

// Google Tag Manager
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PD359M6K');

window.dataLayer = window.dataLayer || [];

document.addEventListener("turbo:load", () => {
  dataLayer.push({ event: 'turbo_load' });
});

// Chat
(function(d,t) {
  var BASE_URL="https://app.chatwoot.com";
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
  g.src=BASE_URL+"/packs/js/sdk.js";
  g.defer = true;
  g.async = true;
  s.parentNode.insertBefore(g,s);
  g.onload=function(){
    window.chatwootSDK.run({
      websiteToken: 'enwWnNgkxiRn9bNEYhrtSSfg',
      baseUrl: BASE_URL
    })
  }
})(document,"script");

if (railsEnv !== "development") {
  Sentry.init({
    dsn: "https://0c6a74e11898c1df10d243f3162bb0e9@o4507702641164288.ingest.de.sentry.io/4507702643720272",
    integrations: [
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.log("Sentry initialized");
}
