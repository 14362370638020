import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainImageLink", "mainImage", "thumbnail"];

  changeImage(event) {

    event.preventDefault();

    // Get the clicked link (thumbnail wrapper)
    const clickedLink = event.currentTarget;

    const image = clickedLink.querySelector('img');

    // Extract the full-sized image URL from the anchor's href or src
    const newMainImageSrc = clickedLink.href ?? clickedLink.src ?? clickedLink.dataset.url;
    // update srcset
    const newMainImageSrcset = image.srcset;
    
    // Update the main image with the new source, and the link of it
    this.mainImageTarget.src = newMainImageSrc;
    this.mainImageTarget.srcset = newMainImageSrcset;
    
  }
}
