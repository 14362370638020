import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "content", "opened"]

  connect() {
    if (this.hasOpenedTarget) {
      this.open()
    }
  }

  reconnect() {
    setTimeout(() => this.connect(), 1); // wait for form to be connected
  }
  
  open() {
    const modal = this.modalTarget;
    modal.classList.remove("hidden");
    requestAnimationFrame(() => {
      modal.classList.add("modal-active");
    });
    this.dispatch("opened")
  }

  close() {
    const modal = this.modalTarget;
    modal.classList.remove("modal-active");
    setTimeout(() => {
      modal.classList.add("hidden");
    }, 300);
  }
}
