import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="markup"
export default class extends Controller {
  static targets = ["form", "percent"]

  selected(event) {
    this.percentTarget.classList.add("text-white-1")
    this.percentTarget.value = event.target.value

    if (this.percentTarget.value) {
      this.formTarget.requestSubmit()
    }
  }
}
