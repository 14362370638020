import { Controller } from "@hotwired/stimulus";


// Connects to data-controller="signup-with-email"
export default class extends Controller {
  static targets = ["firstNameInput", "lastNameInput", "emailInput", "passwordInput", "accceptedTermsAndConditionsInput", "submitInput", "declaredMonthlySalesInput"]
  

  validate() {
    const password = this.passwordInputTarget.value;
    const email = this.emailInputTarget.value;
    const firstNameInput = this.firstNameInputTarget.value;
    const lastNameInput = this.lastNameInputTarget.value;
    const acceptedTermsAndConditions = this.accceptedTermsAndConditionsInputTarget.checked;
    const declaredMonthlySalesInput = this.declaredMonthlySalesInputTarget.value;

    // FIMME: Use zod, validate email
    let valid = false;
    if (password.length > 0 && email.length > 0 && firstNameInput.length > 0 && lastNameInput.length > 0 && acceptedTermsAndConditions && declaredMonthlySalesInput.length > 0) {
      valid = true;
    }

    if (valid) {
      this.submitInputTarget.disabled = false;
    } else {
      this.submitInputTarget.disabled = true;
    }
  }
}
