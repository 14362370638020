import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

function targetToFloat(target) {
  return parseFloat(target.value)
}

export default class extends Controller {
  static targets = [
    "cropperImage",
    "form",
    "aspectRatios",
    "ctaButton", "spinner",
    "originalImage",
    "originalWidth", "originalHeight",
    "canvasWidth", "canvasHeight",
    "boxXOffset", "boxYOffset",
    "boxWidth", "boxHeight",
    "numerator", "denominator",
    "cropper", "imageContainer",
    "placeholder", "spinner", "uploadButtonText",
    "product"
  ]

  // private field for storing the cropper instance
  #cropper = null;
  #isNewProduct = null;
  #originalWidth = null;
  #originalHeight = null;

  connect() {
    this.isNewProduct = this.productTarget.dataset.isNewProduct === "true";
    this.isDesign = this.productTarget.dataset.isDesign === "true";
    this.isManualOrder = this.productTarget.dataset.isManualOrder === "true";
    this.originalWidth = this.productTarget.dataset.originalHeight;
    this.originalHeight = this.productTarget.dataset.originalWidth;

    this.addOpacity();
    if (!this.isNewProduct || this.isDesign) {
      this.showSpinner();
      this.cropperTarget.classList.remove("hidden");
      const cropperImage = this.cropperImageTarget;
      const interval = setInterval(() => {
        if (cropperImage.complete) {
          clearInterval(interval)
          this.originalImageLoaded()
        }
      }, 50)
    }
    if (this.isDesign) {
      this.hidePlaceholder();
    }
  }

  originalImageLoaded() {
    const image = new Image();
    image.src = this.cropperImageTarget.src;
    const originalWidth = this.originalWidth || this.originalWidthTarget.value;
    const originalHeight = this.originalHeight || this.originalHeightTarget.value;

    image.onload = () => {
      this.setDimensionsAndAspectRatio(image, originalWidth, originalHeight, image.width, image.height);
      this.render();
    }
  }

  originalImageAttached() {
    this.hidePlaceholder()
    this.cropperTarget.classList.remove("hidden");
    this.showSpinner()

    const file = this.originalImageTarget.files[0];
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      this.setDimensionsAndAspectRatio(image, image.width, image.height, image.width, image.height)
      this.renderOnImageLoad();
    }

    const cropperImage = this.cropperImageTarget
    const uploadButtonText = this.uploadButtonTextTarget

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        cropperImage.src = e.target.result;
        uploadButtonText.innerHTML = "Change image"
      };
      reader.readAsDataURL(file);
    } else {
      this.showPlaceholder();
    }
  }

  renderOnImageLoad() {
    const cropperImage = this.cropperImageTarget;
    const interval = setInterval(() => {
      if (cropperImage.complete) {
        clearInterval(interval)
        this.render()
      }
    }, 50)
  }

  async crop() {
    const form = this.formTarget;

    this.cropper.disable();
    this.addOpacity();
    this.showSpinner();

    if ((this.isManualOrder && this.hasOriginalImageTarget && !this.isNewProduct) || (this.isDesign && this.hasOriginalImageTarget)) {
      this.originalImageTarget.value = "";
      this.originalImageTarget.removeAttribute("required");
    }
    form.requestSubmit();
  }

  render() {
    const cropperImage = this.cropperImageTarget
    if (cropperImage.cropper) cropperImage.cropper.destroy()

    const boxXOffset = this.boxXOffsetTarget
    const boxYOffset = this.boxYOffsetTarget
    const boxWidth = this.boxWidthTarget
    const boxHeight = this.boxHeightTarget

    const width = targetToFloat(this.canvasWidthTarget)
    const height = targetToFloat(this.canvasHeightTarget)
    const numerator = targetToFloat(this.numeratorTarget)
    const denominator = targetToFloat(this.denominatorTarget)

    let aspectRatio = null
    if (height > width) {
      aspectRatio = denominator / numerator
    } else {
      aspectRatio = numerator / denominator
    }

    const removeOpacity = () => { this.removeOpacity() }
    const hideSpinner = () => { this.hideSpinner() }
    const enableCtaButton = () => { this.enableCtaButton() }

    this.cropper = new Cropper(cropperImage, {
      checkCrossOrigin: false,
      scalable: false,
      zoomable: false,
      movable: false,
      aspectRatio,
      autoCropArea: 1,
      viewMode: 1,
      crop(event) {
        const xOffset = event.detail.x;
        const yOffset = event.detail.y;
        const width = event.detail.width;
        const height = event.detail.height;

        boxXOffset.value = xOffset < 0 ? 0 : Math.round(xOffset)
        boxYOffset.value = yOffset < 0 ? 0 : Math.round(yOffset)
        boxWidth.value = width < 0 ? 0 : Math.round(width)
        boxHeight.value = height < 0 ? 0 : Math.round(height)
      },
      ready() {
        removeOpacity();
        hideSpinner();
        enableCtaButton();
      }
    })
  }

  setDimensionsAndAspectRatio(image, originalWidth, originalHeight, canvasWidth, canvasHeight) {
    this.originalWidthTarget.value = originalWidth;
    this.originalHeightTarget.value = originalHeight;
    this.canvasWidthTarget.value = canvasWidth;
    this.canvasHeightTarget.value = canvasHeight;

    const aspectRatio = this.mostMatchedAspectRatio(image.width, image.height);
    if (image.width > image.height) {
      this.numeratorTarget.value = aspectRatio.numerator;
      this.denominatorTarget.value = aspectRatio.denominator;
    } else {
      this.numeratorTarget.value = aspectRatio.numerator;
      this.denominatorTarget.value = aspectRatio.denominator;
    }
  }

  mostMatchedAspectRatio(width, height) {
    const dimensions = [width, height].sort((a, b) => a - b).map((dimension) => parseFloat(dimension));
    const dimensionsQuotient = dimensions[1] / dimensions[0];
    const mostMatching = this.aspectRatiosTargets.reduce((acc, aspectRatio, index) => {
      const numerator = parseFloat(aspectRatio.dataset.numerator);
      const denominator = parseFloat(aspectRatio.dataset.denominator);
      const quotient = numerator / denominator;
      const diff = Math.abs(dimensionsQuotient - quotient);
      if (index === 0 || diff < acc.diff) {
        return { numerator, denominator, diff };
      }
      return acc;
    }, {});
    return mostMatching;
  }

  hidePlaceholder() {
    if (this.hasPlaceholderTarget) { 
      this.placeholderTarget.classList.add("hidden");
      this.placeholderTarget.classList.remove("block");
    }
  }

  showPlaceholder() {
    if (this.hasPlaceholderTarget) { 
      this.placeholderTarget.classList.add("block");
      this.placeholderTarget.classList.remove("hidden");
    }
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
    this.spinnerTarget.classList.remove("flex");
  }

  showSpinner() {
    this.spinnerTarget.classList.add("flex");
    this.spinnerTarget.classList.remove("hidden");
  }

  addOpacity() {
    this.imageContainerTarget.classList.add("opacity-50");
  }

  removeOpacity() {
    this.imageContainerTarget.classList.remove("opacity-50");
  }

  disableCtaButton() {
    const ctaButton = this.ctaButtonTarget
    ctaButton.classList.add("bg-white-4")
    ctaButton.classList.remove("bg-purple-1")
    ctaButton.classList.add("cursor-not-allowed")
    ctaButton.classList.remove("cursor-pointer")
    ctaButton.disabled = true
    ctaButton.innerHTML = "Please wait..."
  }

  enableCtaButton() {
    const ctaButton = this.ctaButtonTarget
    ctaButton.classList.remove("hidden")
    ctaButton.classList.add("flex")
    ctaButton.classList.add("bg-purple-1")
    ctaButton.classList.remove("bg-white-4")
    ctaButton.classList.add("cursor-pointer")
    ctaButton.classList.remove("cursor-not-allowed")
    ctaButton.disabled = false
  }
}
