import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["data", "placeholder"];

  connect() {
    let data = null;
    let placeholder = null;
    if (this.hasDataTarget && this.hasPlaceholderTarget) {
      data = this.dataTarget;
      placeholder = this.placeholderTarget;
    }
    data = this.dataTarget;
    placeholder = this.placeholderTarget;
    this.element.addEventListener("change", () => {
      if (data !== null && placeholder !== null) {
        data.classList.add("hidden");
      placeholder.classList.remove("hidden");
      }
      
      this.element.requestSubmit();
    });
  }
}
