window.activeAccordion = null;
window.toggleAccordion = function toggleAccordion(id) {
  const content = document.getElementById(`content-${id}`);
  const arrow = document.getElementById(`arrow-${id}`);
  const isOpen = content.style.maxHeight && content.style.maxHeight !== '0px';

  // Close any currently open accordion
  if (activeAccordion && activeAccordion !== id) {
    const activeContent = document.getElementById(`content-${activeAccordion}`);
    const activeArrow = document.getElementById(`arrow-${activeAccordion}`);
    activeContent.style.maxHeight = '0px';
    activeArrow.classList.remove('rotate-180');
  }

  // Toggle the clicked accordion
  if (isOpen) {
    content.style.maxHeight = '0px';
    arrow.classList.remove('rotate-180');
    activeAccordion = null;
  } else {
    content.style.maxHeight = content.scrollHeight + 'px'; // Set to scrollHeight for full expansion
    arrow.classList.add('rotate-180');
    activeAccordion = id;
  }
}

function updateRevenue() {
  const orders = document.getElementById('orderRange').value;
  const average = document.getElementById('averageorderRange').value;
  const revenueValue = orders * average;
  const profitValue = revenueValue * 0.5;
  document.getElementById('revenue').textContent = revenueValue.toLocaleString("en-US");
  document.getElementById('profit').textContent = profitValue.toLocaleString("en-US");
}

// Function to initialize and update slider values
function setupRevenueCalculatorSlider(sliderId, displayId) {

  const slider = document.getElementById(sliderId);
  if(!slider) return;
  
  const display = document.getElementById(displayId);

  // Function to update display and fill color
  function updateDisplay() {
    const value = slider.value;
    display.textContent = value.toLocaleString("en-US");
    slider.style.setProperty('--percent', slider.value / slider.max * 100 + '%');
  }

  // Initialize with initial values
  updateDisplay();
  updateRevenue();

  // Update on slider input
  slider.addEventListener('input', updateDisplay);
  slider.addEventListener('input', updateRevenue);
}

document.addEventListener('turbo:load', function() {
  // Setup each slider with its respective interval multiplier
  setupRevenueCalculatorSlider('orderRange', 'orderValue');
  setupRevenueCalculatorSlider('averageorderRange', 'averageValue');

  // If jQuery is available, initialize the slick slider
  if (typeof $ === 'function') {
    $('.real-slider').slick();
  }
})
